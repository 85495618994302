var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "name": "form_auth",
      "method": "post",
      "action": _vm.gatewayURL
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "site_cd"
    },
    domProps: {
      "value": _vm.siteCode
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ordr_idxx"
    },
    domProps: {
      "value": _vm.orderID
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "req_tx",
      "value": "cert"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cert_method",
      "value": "01"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cert_otp_use",
      "value": "Y"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cert_enc_use_ext",
      "value": "Y"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "web_siteid",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "Ret_URL"
    },
    domProps: {
      "value": _vm.returnURL
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "param_opt_1"
    },
    domProps: {
      "value": _vm.param_opt_1
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "param_opt_2"
    },
    domProps: {
      "value": _vm.param_opt_2
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "param_opt_3"
    },
    domProps: {
      "value": _vm.param_opt_1
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "veri_up_hash"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "up_hash"
    },
    domProps: {
      "value": _vm.uphash
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "kcp_cert_lib_ver"
    },
    domProps: {
      "value": _vm.version
    }
  }), _c('iframe', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "kcp_cert",
      "name": "kcp_cert",
      "width": "100%",
      "height": "700",
      "frameborder": "0",
      "scrolling": "no"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }